import React, { useState, useEffect } from 'react';
import { Row, Col, CardBody, Card, Container, CardHeader, Form, Media, Label } from 'reactstrap';
import {Link} from "react-router-dom";
import { AdvancedTable, ButtonGroup, Button, DeletionModal, FormRow } from '../../components';
import { HeaderMain } from "../../components/HeaderMain";
import { prepareSystemServiceQuery, useSocket } from '../../contexts/SocketContext';
import { FileUploader } from 'react-drag-drop-files';
import { ThemeConsumer } from '../../components/Theme';
import { toast } from 'react-toastify';

export function FileManager() {
    const {socket} = useSocket();

    const [ruleValues, setRuleValues] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tableNote, setTableNote] = useState("");
    const [message, setMessage] = useState("")

    const [file, setFile] = useState(null);
    const handleChange = (file) => {
        setFile(file);
    };

    useEffect(() => {
        refreshValues();
        const interval = setInterval(() => {
            refreshValues();
        }, 30000);
        return () => clearInterval(interval);

    }, [socket]);

    function refreshValues() {
        if(socket !== null && socket.isOpened) {
            setLoading(true)
            socket.sendRequest(prepareSystemServiceQuery("GET", "files")).then(res => {
                if(res.response !== undefined) {
                    setRuleValues(res.response);
                    setLoading(false)
                }
            });
        }
    }

    function deleteValue(cell) {
        if (socket !== null && socket.isOpened) {
            setRuleValues([]);
            setLoading(true);
            if (socket !== null && socket.isOpened) {
                socket.sendRequest(prepareSystemServiceQuery("DELETE", "files", {id: cell})).then((res) => {
                    if(res.response.error) {
                        setTableNote(res.response.error)
                    } else {
                        setTableNote("")
                        toast.success(contentSuccess)
                    }
                    refreshValues()
                })
            }
        }
    }

    function uploadFile() {
        if(file !== "") {
            const formData = new FormData();
            let headers = new Headers();

            formData.append('file', file);
            formData.append("title", file.name);
            formData.append("description", file.name);

            headers.set('Authorization', `Basic ${localStorage.getItem("wsToken")}`);

            fetch(`${localStorage.getItem("serverIp")}/file`, {
                    method: 'POST',
                    headers: headers,
                    body: formData,
                }
            )
            .then((response) => response.json())
            .then((res) => {
                if(res.success) {
                    refreshValues()
                    toast.success(contentSuccess)
                } else {
                    setMessage(res.message)
                }
            })
            .catch((error) => {
                setMessage(error)
            });
        } else {
            setMessage("No File Selected")
        }
    }

    const contentSuccess = ({ closeToast }) => (
        <Media>
            <Media middle left className="mr-3">
                <i className="fa fa-fw fa-2x fa-check"></i>
            </Media>
            <Media body>
                <Media heading tag="h6">
                    Success!
                </Media>
                <p>
                    Action Done Successfully
                </p>
                <div className="d-flex mt-2">
                    <Button color="success" onClick={() => { closeToast() }} >
                        Close
                    </Button>
                </div>
            </Media>
        </Media>
    );
    
    return (
        <Container>
            <Row className="mb-5">
                <Col lg={12}>
                    <HeaderMain
                        title="Files"
                        className="mb-3"
                    />
                </Col>
                <Col lg={12} style={{marginBottom: "20px"}}>
                    <Card>
                        <CardHeader>
                            Upload File
                            {message !== "" && " - " + message}
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <FormRow>
                                    <Col sm={12} style={{marginTop: "10px"}}>
                                        <FileUploader
                                            multiple={false}
                                            handleChange={handleChange}
                                            name="file"
                                            style={{maxWidth: null}}
                                            classes="drop_area"
                                        />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Col sm={12} style={{marginTop: "10px"}}>
                                        <ThemeConsumer>
                                            {
                                                ({ color }) => (
                                                    /* Make color as the same of parent */
                                                    <Button color={color} outline={false} size="md" onClick={uploadFile}>Upload File</Button>
                                                )
                                            }
                                        </ThemeConsumer>
                                    </Col>
                                </FormRow>
                            </Form>

                        </CardBody>
                    </Card>
                
                </Col>
                <Col lg={12}>
                    <Card>
                        <CardHeader>
                            Available Files
                        </CardHeader>
                        <CardBody>
                            <AdvancedTable
                                loading={loading}
                                tableNote={<div className='text-red'>{tableNote}</div>}
                                columns={[
                                    {
                                        dataField: 'title',
                                        text: 'File Title',
                                        sort: true
                                    }, {
                                        dataField: 'filename',
                                        text: 'File Name',
                                        sort: true
                                    }, {
                                        dataField: "actions",
                                        text: "Actions",
                                        sort: false,
                                        formatter: function name(cell) {
                                            return (
                                                <ButtonGroup>
                                                    <DeletionModal assetName={cell[0]}
                                                        successFunction={deleteValue}
                                                        successFunctionProp={cell[1] + ""} />
                                                </ButtonGroup>
                                            );
                                        } 
                                    }
                                ]}
                                keyField="name"
                                data={
                                    Array.isArray(ruleValues) ? ruleValues.map(returnResource => ({
                                        ...returnResource,
                                        key: returnResource._id,
                                        actions: [returnResource.fileId, returnResource._id]
                                    })) : []
                                }
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>

    );
}

export default FileManager;