import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import PropTypes from 'prop-types';

import {
    Row,
    Col
} from 'reactstrap';
import { CustomExportCSV } from './CustomExportButton';
import { CustomSearch } from './CustomSearch';
import { GboxButton as Button } from './Button';
import { Link } from "react-router-dom";
import { GboxButtonGroup as ButtonGroup } from "./ButtonGroup"
import _ from "lodash"

const sortCaret = (order) => {
    if (!order)
        return <i className="fa fa-fw fa-sort text-muted"></i>;
    if (order)
        return <i className={`fa fa-fw text-muted fa-sort-${order}`}></i>
};

export class AdvancedTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchDependency: "",
            filteredData: null
        }
        this.setSearchState = this.setSearchState.bind(this)
    }

    setSearchState(value) {
        this.setState({ searchDependency: value })
        this.setState({filteredData: this.search({data: this.props.data !== undefined ? this.props.data : {}, columns: this.newColumns(), searchText: value})})
    }

    search(props) {
        const { data, columns } = props;
        const searchText = props.searchText.toLowerCase();
        return data.filter((row, ridx) => {
          for (let cidx = 0; cidx < columns.length; cidx += 1) {
            const column = columns[cidx];
            if (column.searchable === false) continue;
            let targetValue = _.get(row, column.dataField);
            if (column.formatter) {
              targetValue = column.formatter(targetValue, row, ridx, column.formatExtraData);
            } else if (column.filterValue) {
              targetValue = column.filterValue(targetValue, row);
            }
            if (targetValue !== null && typeof targetValue !== 'undefined') {
                targetValue = targetValue.toString().toLowerCase();
                if (targetValue.indexOf(searchText) > -1) {
                  return true;
                }
              }
          }
          return false;
        });
      }
      
      newColumns = () => {
        if (this.props.columns === undefined) {
            return {}
        }
        var newColumnsDef = [];
        this.props.columns.forEach(column => {
            var addColumn = true;
            if (column.searchDependency !== undefined && column.searchDependency == true) {
                if (this.state.searchDependency === "") {
                    addColumn = false;
                }
            }
            if (addColumn) {
                newColumnsDef = [...newColumnsDef, { ...column, sortCaret }];
            }
        })
        return newColumnsDef;
    }

    render() {
        const expandRow = {
            renderer: row => (
                <Row>
                    <Col md={6}>
                        <dl className="row">
                            {
                                (typeof this.props.expandRow === "object") && Object.keys(this.props.expandRow).slice(0, Object.keys(this.props.expandRow).length / 2).map(expandKey => (
                                    Object.keys(row).map((value, key) => (
                                        expandKey == value &&
                                        <React.Fragment key={key}>
                                            <dt className="col-sm-6 text-right">{this.props.expandRow[expandKey]}</dt>
                                            <dd className="col-sm-6">{row[value]}</dd>
                                        </React.Fragment>

                                    ))
                                ))
                            }

                        </dl>

                    </Col>
                    <Col md={6}>
                        <dl className="row">
                            {
                                (typeof this.props.expandRow === "object") && Object.keys(this.props.expandRow).slice(Object.keys(this.props.expandRow).length / 2, Object.keys(this.props.expandRow).length).map(expandKey => (
                                    Object.keys(row).map((value, key) => (
                                        expandKey == value &&
                                        <React.Fragment key={key}>
                                            <dt className="col-sm-6 text-right">{this.props.expandRow[expandKey]}</dt>
                                            <dd className="col-sm-6">{row[value]}</dd>
                                        </React.Fragment>

                                    ))
                                ))
                            }

                        </dl>

                    </Col>
                    {
                        this.props.advancedExpandRow !== undefined && row[this.props.advancedExpandRow] !== undefined &&
                        row[this.props.advancedExpandRow]
                    }
                </Row>
            ),
            showExpandColumn: true,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => isAnyExpands ? (
                <i className="fa fa-angle-down fa-fw fa-lg text-muted"></i>
            ) : (
                <i className="fa fa-angle-right fa-fw fa-lg text-muted"></i>
            ),
            expandColumnRenderer: ({ expanded }) =>
                expanded ? (
                    <i className="fa fa-angle-down fa-fw fa-lg text-muted"></i>
                ) : (
                    <i className="fa fa-angle-right fa-fw fa-lg text-muted"></i>
                )
        }

        return (
            <React.Fragment>
                <div className="d-flex justify-content-end align-items-center mb-2">
                    {
                        this.props.tableNote &&
                        <div className="justify-content-start d-flex">
                            {
                                this.props.tableNote
                            }
                        </div>
                    }
                    <div className="d-flex ml-auto">
                        <CustomSearch
                            className="mr-2 ml-2"
                            updateSearch={this.setSearchState}
                        />
                        <ButtonGroup>
                            <CustomExportCSV
                                columns={this.newColumns()}
                                data={this.state.filteredData ? this.state.filteredData : this.props.data}
                            >
                                Export
                            </CustomExportCSV>
                            {
                                this.props.additionalButtons && this.props.additionalButtons.map(button => (
                                    <Button key={button.to}
                                        tag={Link} to={button.to}>
                                        {button.title}
                                    </Button>
                                ))
                            }
                            {
                                this.props.to &&
                                <Button
                                    tag={Link} to={this.props.to}>
                                    Add <i className="fa fa-fw fa-plus" />
                                </Button>
                            }

                        </ButtonGroup>
                    </div>
                </div>
                <BootstrapTable
                    classes="responsiveTable"
                    bordered={this.props.bordered ? this.props.bordered : false}
                    expandRow={this.props.expandRow && expandRow}
                    data={this.state.filteredData ? this.state.filteredData : this.props.data}
                    columns={this.newColumns()}
                    keyField={this.props.keyField !== undefined ? this.props.keyField : "id"}
                    responsive
                    hover
                    striped
                    search={<CustomSearch />}
                    noDataIndication={() => {
                        if (this.props.loading !== undefined && this.props.loading) {
                            return (
                                <div style={{ width: "100%", textAlign: "center", display: "flex", justifyContent: "center" }}><div className="spinner" /></div>
                            )
                        }
                    }}
                    {...this.props.baseProps}
                />
            </React.Fragment>

        );
    }
}

AdvancedTable.propTypes = {
    expandRow: PropTypes.object,
    to: PropTypes.string,
    additionalButtons: PropTypes.array,
    keyField: PropTypes.string,
    data: PropTypes.array,
    columns: PropTypes.array,
    tableNote: PropTypes.element,
    advancedExpandRow: PropTypes.string,
    bordered: PropTypes.bool,
    loading: PropTypes.bool
}