import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { useSocket } from "../../contexts/SocketContext"
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import themeContext from '../../components/Theme/ThemeContext';
import { useEffect } from 'react';
import { useState } from 'react';

export function NavbarUser() {
    const { socket, setSocketConn } = useSocket();
    const theme = useContext(themeContext)
    
    const [companyTypes, setCompanyTypes] = useState([])

    useEffect(() => {
        const companyTypes = localStorage.getItem("types");
        if (companyTypes !== undefined && companyTypes !== null) {
            setCompanyTypes(companyTypes.split(","));
        }
    }, [])

    function logOut() {
        if (socket !== undefined && socket !== null) {
            socket.close();
        }
        localStorage.removeItem("wsToken");
        setSocketConn(null);
    }

    return (
        <UncontrolledDropdown nav inNavbar dir="ltr" >
            <DropdownToggle nav>
                <i className="fa fa-lg fa-gear" />
            </DropdownToggle>
            <DropdownMenu right={theme.dir === "ltr"}>
                <DropdownItem>
                    <i className="fa fa-fw fa-cogs mr-2"></i>
                    Settings
                </DropdownItem>
                <DropdownItem tag={Link} to="/app/files">
                    <i className="fa fa-fw fa-file mr-2"></i>
                    Files
                </DropdownItem>
                <DropdownItem tag={Link} to="/app/company">
                    <i className="fa fa-fw fa-users mr-2"></i>
                    Companies
                </DropdownItem>
                <DropdownItem tag={Link} to="/app/users">
                    <i className="fa fa-fw fa-user mr-2"></i>
                    Users
                </DropdownItem>
                <DropdownItem tag={Link} to="/app/branch">
                    <i className="fa fa-fw fa-hotel mr-2"></i>
                    Branches
                </DropdownItem>
                <DropdownItem tag={Link} to="/app/service">
                    <i className="fa fa-fw fa-rocket mr-2"></i>
                    Services
                </DropdownItem>
                <DropdownItem tag={Link} to="/app/remote" >
                    <i className="fa fa-fw fa-bullseye mr-2"></i>
                    Remote Devices
                </DropdownItem>
                <DropdownItem tag={Link} to="/app/versions" >
                    <i className="fa fa-fw fa-cube mr-2"></i>
                    Versions
                </DropdownItem>
                <DropdownItem tag={Link} to="/app/applications" >
                    <i className="fa fa-fw fa-lightbulb-o mr-2"></i>
                    Applications
                </DropdownItem>
                <DropdownItem tag={Link} to="/app/mapping" >
                    <i className="fa fa-fw fa-map mr-2"></i>
                    Mapping
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem tag={Link} to="/pages/login" onClick={logOut}>
                    <i className="fa fa-fw fa-power-off  mr-2"></i>
                    Logoff
                </DropdownItem>

            </DropdownMenu>
        </UncontrolledDropdown>
    )
}
